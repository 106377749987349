const prepareDate = (date: string) => {
	const dateVariable = new Date(date);
	const dateInInt = dateVariable.getTime();

	const isDateValid = !isNaN(dateInInt);

	const year = dateVariable.getUTCFullYear();
	const month = dateVariable.getUTCMonth() + 1;
	const day = dateVariable.getUTCDate();

	return isDateValid ? `${year}-${month}-${day}` : "";
};

export default prepareDate;
